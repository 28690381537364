<template>
  <v-container fluid>
    <AError :api="apiGetSurvey" />

    <transition name="fade">
      <v-alert
        dense
        border="left"
        type="warning"
        class="text-pre-wrap"
        v-if="alertError.length > 0"
        ><span
          class="d-block"
          v-for="(error, index) in alertError"
          :key="index"
          >{{ error }}</span
        ></v-alert
      >
    </transition>

    <v-card>
      <v-toolbar :color="HRDC_BLUE_THEME_COLOR">
        <v-btn icon class="hidden-xs-only text-white" @click="redirectBack()">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>

        <v-toolbar-title v-if="!loadingDialog">
          <div class="d-flex align-center text-white">
            {{ model.name.plural }}
          </div>
        </v-toolbar-title>

        <v-spacer></v-spacer>

        <!-- <span v-if="isHrdcSuperAdminRoles">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon color="white" class="hidden-xs-only">
                <v-icon dark v-bind="attrs" v-on="on"> mdi-cog-play </v-icon>
              </v-btn>
            </template>
            <span>Execute Cron Job</span>
          </v-tooltip>
        </span> -->
      </v-toolbar>

      <div>
        <div class="pa-5">
          <v-data-table
            :headers="headers"
            :items="surveyDatas"
            :items-per-page="10"
            class="elevation-1"
            :loading="apiGetSurvey.isLoading"
          >
            <template v-slot:item.actions="{ item }">
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    :color="HRDC_BLUE_THEME_COLOR"
                    dark
                    v-bind="attrs"
                    v-on="on"
                  >
                    Action
                  </v-btn>
                </template>
                <v-list dense>
                  <v-list-item
                    v-for="(action, index) in item.action"
                    :key="index"
                    link
                    @click="listAction(action, item)"
                  >
                    <v-list-item-title>{{ action }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
          </v-data-table>
        </div>
      </div>
    </v-card>

    <SurveyResult
      :surveyResultModal="surveyResultModal"
      :surveyResultData="surveyResultData"
      @toggleSurveyResultModal="toggleSurveyResultModal"
    ></SurveyResult>

    <HrdcLoader :loadingDialog="loadingDialog"></HrdcLoader>
  </v-container>
</template>

<script>
import moment from "moment";
import Api from "@/objects/api";
import { mapState } from "vuex";
import Model from "@/objects/model";
import Service from "@/objects/service";
import HrdcData from "@/services/hrdc/objects/globalData";
import SurveyResult from "./SurveyResult";

export default {
  components: { SurveyResult },
  computed: {
    ...mapState({
      auth: (state) => state.auth.data,
    }),
  },
  props: ["params"],
  data: () => ({
    apiGetSurvey: new Api(),
    apiGetSurveyAnalytic: new Api(),
    model: new Model(),
    service: new Service(),
    hrdcData: new HrdcData(),
    alertError: [],
    errorField: {
      applicationNo: null,
    },
    loadingDialog: false,
    headers: [
      {
        text: "No.",
        align: "start",
        sortable: false,
        value: "index",
      },
      { text: "Title", value: "title", sortable: false },
      { text: "Created Date", value: "timestampCreated", sortable: false },
      { text: "Actions", value: "actions", sortable: false, align: "center" },
    ],
    surveyDatas: [],
    surveyResultModal: false,
    surveyResultData: {},
    surveyResult: [],
  }),
  async created() {
    this.showLoadingDialog();
    this.model.getByKey(this.params.modelKey);
    if (this.model.serviceKey) this.service.set("key", this.model.serviceKey);

    // Assign apiDetails by mapping repository.
    const apiDetails = {
      apiGetSurvey: {
        url: `${this.$service[this.service.key]}/v1/en/console/survey`,
      },
      apiGetSurveyAnalytic: {
        url: `${
          this.$service[this.service.key]
        }/v1/en/console/survey/get-survey-analytic-by-uuid`,
      },
    };

    for (const api in apiDetails) {
      if (apiDetails[api].url !== undefined) {
        this[api].setUrl(apiDetails[api].url);
      }
      if (apiDetails[api].method !== undefined) {
        this[api].setMethod(apiDetails[api].method);
      }
      if (apiDetails[api].query !== undefined) {
        this[api].setQuery(apiDetails[api].query);
      }
      if (apiDetails[api].params !== undefined) {
        this[api].setParams(apiDetails[api].params);
      }
    }

    this.stages = await this.hrdcData.allStages;
    this.rolesAssignation(this.auth.roles);

    this.surveyDatas = [];
    this.apiGetSurvey.setCallbackCompleted((response) => {
      try {
        const { status, data } = response;

        if (!status) {
          this.$store.dispatch("showMessage", {
            message: data,
            messageType: "error",
            timeout: 2000,
          });

          return;
        }

        if (status) {
          if (data) {
            let index = 1;
            for (const item of data) {
              const { id, uuid, title, timestampCreated } = item;

              this.surveyDatas.push({
                id,
                uuid,
                index,
                title,
                timestampCreated: moment(timestampCreated).format(
                  "YYYY-MM-DD HH:mm:ss"
                ),
                action: ["View Analytics"],
              });
              index++;
            }
          }
        }
        this.hideLoadingDialog();
      } catch (err) {
        console.log(err);
      }
    });
    this.apiGetSurvey.fetch();

    this.hideLoadingDialog();
  },
  methods: {
    redirectBack() {
      let route = "HrdcSettings";
      if (this.params.backTo) {
        route = this.params.backTo;
      }

      this.$router.push({
        name: route,
        params: {
          modelKey: this.model.key,
          backTo: "",
        },
      });
    },
    listAction(action, item) {
      switch (action) {
        case "View Analytics":
          // Get survey data by uuid.
          this.showLoadingDialog();
          this.apiGetSurveyAnalytic.setQuery(`surveyUuid=${item.uuid}`);
          this.apiGetSurveyAnalytic.setCallbackCompleted((response) => {
            try {
              const { status, data } = response;
              if (status) {
                let chartData = {};
                let chartOptions = {};
                const { surveyQuestion, surveyResult, groupSurveyResponses } =
                  data;

                this.surveyResult = surveyResult;
                for (const question of surveyQuestion) {
                  // Store question to chartOptions.
                  const chartOpts = this.getChartOptions(question);
                  chartOptions[question.uuid] = chartOpts;

                  const chartItem = this.getChartData(question);
                  chartData[question.uuid] = chartItem;
                }

                this.$set(this.surveyResultData, "survey", item);
                this.$set(
                  this.surveyResultData,
                  "groupSurveyResponses",
                  groupSurveyResponses
                );
                this.$set(
                  this.surveyResultData,
                  "surveyQuestion",
                  surveyQuestion
                );
                this.$set(this.surveyResultData, "chartOptions", chartOptions);
                this.$set(this.surveyResultData, "chartData", chartData);

                // Open result modal.
                this.surveyResultModal = true;
              }
            } catch (err) {
              console.log(err);
            } finally {
              this.hideLoadingDialog();
            }
          });
          this.apiGetSurveyAnalytic.fetch();
          break;
      }
    },
    toggleSurveyResultModal(val) {
      this.surveyResultModal = val;
    },
    getChartOptions(question) {
      switch (question.answerType) {
        default:
        case "radio":
          return {
            chartType: "PieChart",
            title: question.question,
            pieSliceText: "value",
            pieHole: 0.2,
          };

        case "checkbox":
          return {
            chartType: "BarChart",
            title: question.question,
            chartArea: { width: "30%" },
            hAxis: {
              minValue: 0,
            },
            legend: { position: "none" },
          };
      }
    },
    getChartData(question) {
      const formattedData = [];
      const answerMap = {};
      const { answerType, SurveyOption } = question;
      const results = this.surveyResult.filter(
        (result) => result.surveyQuestionUuid == question.uuid
      );
      let latestResponses = "";
      let textareaResponses;

      SurveyOption.forEach((option) => {
        answerMap[option.option] = 0;
      });

      switch (answerType) {
        default:
        case "radio":
          for (const result of results) {
            const parsedAns = JSON.parse(result.answer);
            if (Object.prototype.hasOwnProperty.call(answerMap, parsedAns)) {
              answerMap[parsedAns] += result._count._all;
            }
          }

          for (const [key, value] of Object.entries(answerMap)) {
            formattedData.push([key, value]);
          }

          return [["Question", "Responses"], ...formattedData];

        case "checkbox": // Structure is an array.
          for (const result of results) {
            const parsedAnsArr = JSON.parse(result.answer);

            for (const parseAns of parsedAnsArr) {
              if (Object.prototype.hasOwnProperty.call(answerMap, parseAns)) {
                answerMap[parseAns] += result._count._all;
              }
            }
          }

          for (const [key, value] of Object.entries(answerMap)) {
            formattedData.push([
              key,
              value,
              `color: ${this.stringToColor(key + value)}`,
            ]);
          }

          return [
            ["Question", "Responses", { role: "style" }],
            ...formattedData,
          ];

        case "textarea":
          textareaResponses = results;

          for (const result of results.slice(0, 2)) {
            latestResponses += `${JSON.parse(result.answer)}<br>`;
          }

          return {
            latestResponses,
            textareaResponses,
          };
      }
    },
  },
};
</script>
