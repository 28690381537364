<template>
  <v-container>
    <v-dialog
      v-model="surveyResultModal"
      transition="dialog-bottom-transition"
      max-width="1000"
      @click:outside="toggleSurveyResultModal(false)"
    >
      <v-card>
        <v-toolbar :color="HRDC_BLUE_THEME_COLOR" dark
          >Responses Overview -
          {{
            surveyResultData.survey ? surveyResultData.survey.title : null
          }}</v-toolbar
        >

        <v-card-text>
          <v-row class="d-flex px-5 pt-5">
            <v-col lg="4" md="12" sm="12">
              <v-card elevation="1">
                <div>
                  <v-list-item>
                    <v-list-item-content>
                      <div class="text-overline mb-4">Responses</div>
                      <v-list-item-title class="text-h5 mb-1">
                        {{
                          surveyResultData.groupSurveyResponses
                            ? surveyResultData.groupSurveyResponses.length
                            : 0
                        }}
                      </v-list-item-title>
                    </v-list-item-content>
                    <v-icon class="align-self-auto"> mdi-account-group </v-icon>
                  </v-list-item>
                </div>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-text>
          <div
            class="pa-5"
            v-for="(chart, key) of surveyResultData.surveyQuestion"
            :key="key"
          >
            <v-row>
              <v-col cols="12">
                <v-card elevation="0" outlined>
                  <GChart
                    v-if="!SURVEY_RESTRICT_FIELD.includes(chart.answerType)"
                    :type="surveyResultData.chartOptions[chart.uuid].chartType"
                    :data="surveyResultData.chartData[chart.uuid]"
                    :options="surveyResultData.chartOptions[chart.uuid]"
                  />
                  <div v-else>
                    <div v-if="chart.answerType == 'textarea'">
                      <div
                        class="d-flex justify-space-between align-center fill-height px-6 py-3"
                      >
                        <div
                          class="font-weight-bold d-flex justify-center align-center flex-grow-1"
                        >
                          {{ chart.question }}
                        </div>
                        <div>
                          <a
                            href="#"
                            @click="
                              displayAllTextareaResult(
                                surveyResultData.chartData[chart.uuid]
                                  .textareaResponses
                              )
                            "
                            >More Details</a
                          >
                        </div>
                      </div>
                      <v-row class="pa-5">
                        <v-col cols="6" class="text-center">
                          <span class="text-h5">{{
                            surveyResultData.chartData[chart.uuid]
                              .textareaResponses.length
                          }}</span
                          ><br />
                          Responses
                        </v-col>
                        <v-col cols="6" class="text-center">
                          <span class="text-muted">Latest Responses</span><br />
                          <span
                            v-html="
                              surveyResultData.chartData[chart.uuid]
                                .latestResponses
                            "
                          ></span>
                        </v-col>
                      </v-row>
                    </div>
                  </div>
                </v-card>
              </v-col>
            </v-row>
          </div>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn text @click="toggleSurveyResultModal(false)">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="textareaResultModal"
      transition="dialog-bottom-transition"
      max-width="500"
    >
      <v-card>
        <v-toolbar :color="HRDC_BLUE_THEME_COLOR" dark>Responses</v-toolbar>
        <v-card-text class="pa-5">
          <v-data-table
            dense
            :headers="textareaHeaders"
            :items="textareaResponses"
            :items-per-page="10"
            class="elevation-1"
          ></v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { GChart } from "vue-google-charts/legacy";

export default {
  name: "SurveyResult",
  props: {
    surveyResultModal: Boolean,
    // Reference: https://developers.google.com/chart/interactive/docs/gallery/piechart#configuration-options
    surveyResultData: Object,
  },
  emits: ["toggleSurveyResultModal"],
  components: { GChart },
  data: () => ({
    textareaResultModal: false,
    textareaHeaders: [
      {
        text: "No.",
        align: "center",
        sortable: false,
        value: "index",
      },
      { text: "Responses", align: "center", value: "responses" },
    ],
    textareaResponses: [],
  }),
  methods: {
    toggleSurveyResultModal(val) {
      this.$emit("toggleSurveyResultModal", val);
    },
    displayAllTextareaResult(data) {
      this.textareaResponses = data.map((item, index) => {
        return {
          index: index + 1,
          responses: JSON.parse(item.answer),
        };
      });
      this.textareaResultModal = true;
    },
  },
};
</script>
